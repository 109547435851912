
@page {
    @bottom-center {
        content: element(printFooter);
    }
}
@media print {
    .hide-print {
        display: none;
    }

    .footer {
        position: fixed;
        bottom: 5%;
    }

    .largePrint div {
        font-size: 1rem !important;
    }

    .printFooter {
        left: 15px;
    }
  
}
.hide-print {
    float:right;
}
.printDiv{
    padding-left: 10px;
    padding-right: 10px;
    max-width: 860px;
}

.printHeader {
    justify-content: center;
    font-size: 0.6em;
}
#printFooter {
    font-size: 0.7em;
    position:running(printFooter);
    bottom: 10px;
    width: 95%;
    max-width: 840px;
}
