.MuiFormControl-root {
    margin: 5px !important;
}
.MuiInputLabel-root {
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 4px;
    z-index: 1;
}

.MuiSelect-root {
    & .MuiPaper-root {
        transition: none !important;
    }
}


.ciIcons {
    height: 20px !important;
    width: 20px !important;
}

/* Chrome, Safari, Edge, Opera */
#periodValues::-webkit-outer-spin-button,
#periodValues::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#periodValues[type=number] {
  -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
#newWaste::-webkit-outer-spin-button,
#newWaste::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#newWaste[type=number] {
  -moz-appearance: textfield;
}
#proNewLinealPerHr::-webkit-outer-spin-button,
#proNewLinealPerHr::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#proNewLinealPerHr[type=number] {
  -moz-appearance: textfield;
}
#cusomption1::-webkit-outer-spin-button,
#cusomption1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#cusomption1[type=number] {
  -moz-appearance: textfield;
}
#cusomption2::-webkit-outer-spin-button,
#cusomption2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#cusomption2[type=number] {
  -moz-appearance: textfield;
}
#trainingsec::-webkit-outer-spin-button,
#trainingsec::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#trainingsec[type=number] {
  -moz-appearance: textfield;
}


#downTImesec::-webkit-outer-spin-button,
#downTImesec::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#downTImesec[type=number] {
  -moz-appearance: textfield;
}

#avgSpeed::-webkit-outer-spin-button,
#avgSpeed::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#avgSpeed[type=number] {
  -moz-appearance: textfield;
}


#consumptionBaseline::-webkit-outer-spin-button,
#consumptionBaseline::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#consumptionBaseline[type=number] {
  -moz-appearance: textfield;
}

#avgWidth::-webkit-outer-spin-button,
#avgWidth::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#avgWidth[type=number] {
  -moz-appearance: textfield;
}

#actualPrcWaste::-webkit-outer-spin-button,
#actualPrcWaste::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#actualPrcWaste[type=number] {
  -moz-appearance: textfield;
}

#preprint::-webkit-outer-spin-button,
#preprint::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#preprint[type=number] {
  -moz-appearance: textfield;
}